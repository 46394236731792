<template>
  <v-audio
    :src="audioSource"
    @delete="setDefaultProperties"
    @set-duration="checkDuration"
    v-if="audioSource"
    ref="audio"
    :class="{ inline, 'record-player': true }"
  >
    <next-button :disabled="disabled" :next="sendRecordedVoice" />
  </v-audio>

  <div class="record" v-else>
    <div class="record__not-allowed" v-if="!allowed">
      Grant permission to access the microphone and reload this page
    </div>

    <div class="record__body" v-else>
      <transition name="scale" mode="out-in" appear>
        <div v-if="loading" class="record__loader" key="loading">
          <loader container info small />
        </div>
        <start-button
          v-else-if="!recording"
          :start="startRecording"
          :type="type"
          key="btn-record"
        />
        <calibration
          v-else-if="isCalibration"
          :time="calibrationTimer"
          key="calibration"
        />
        <stop-button
          v-else
          :stop="stopRecording"
          :disabled="isStopBtnDisabled"
          :time="recordTimeFormatted"
          key="btn-stop"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import NoSleep from "nosleep.js";
import { delay } from '@/modules/utils';
import { mapState, mapActions } from "vuex";
import { isSupportedBrowser, isIOS, isAndroid } from "@/utils/system";
import { formatTime } from "@/utils/date";
import { saveLog } from "@/modules/logger";
import { sendSampleData } from "@/api";
import vAudio from "@/components/audio";
import AudioRecorder from "@/modules/record";
import Loader from "@/components/loader";
import NextButton from "./components/nextButton";
import Calibration from "./components/calibration";
import StartButton from "./components/startButton";
import StopButton from "./components/stopButton";
import { setStorage, getStorage } from "@/modules/localStorage";

import {
  MSG_UNSUPPORTED,
  POPUP_LOST_STREAM,
  RECORD_MAX_DURATION,
  POPUP_NOTIFICATION,
  STORE_SET_AUDIO_SRC,
  STORE_SET_RECORDING,
  STORE_SET_UPLOADING,
  RECORD_OD_SILENCE,
  RECORD_IOS_SILENCE,
  POPUP_DETECTED_NOISE,
  POPUP_DETECTED_LOW_EFFORT,
  POPUP_NO_COUGH_DETECTED,
  POPUP_NO_VOICE_DETECTED,
  POPUP_NO_FE_DETECTED,
  POPUP_INSUFFICIENT_FE_DETECTED,
  POPUP_SESSION_NOISE_ERROR,
  POPUP_SESSION_RECORDING_ERROR,
  ROUTE_READING_PASSAGE ,
  ROUTE_THREE_DEEP_BREATHS,
  ROUTE_COUNT_TO_20,
  ROUTE_FEELING_TODAY,
  FORCE_RERECORD_POPUPS
} from "@/constants";

const MSGS_MIN_DURATION = {
  audio2: "Recording too short. Please re-record and exert maximum effort.",
  audio5: "Recording too short. Please re-record and read at your normal rate.",
  audio6: "Recording too short. Please re-record at your normal rate.",
};

export default {
  components: {
    vAudio,
    Loader,
    NextButton,
    Calibration,
    StartButton,
    StopButton,
  },

  inject: {
    showModal: { default: () => {} },
  },

  props: {
    type: { type: String, default: "record" },
    voiceKey: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    minDuration: { type: Number, required: true },
    route: { type: String, required: true },
  },

  data: () => ({
    voiceBlob: null,
    calibrationTimer: 5,
    recordTimer: 0,
    allowed: true,
    isStopBtnDisabled: false,
    interval: null,
    loading: false,
    recorder: new AudioRecorder(),
    nosleep: new NoSleep(),
    rtmessage: "No rt message",
    esnr: null,
    prd: null,
    aahPdr: 0,
    aahPdrlln: 0,
    threshold: null,
    phonation_detected: false,
    agg_received: false,
  }),

  computed: {
    ...mapState(["user", "recording", "audioSource"]),

    isCalibration() {
      return this.type === "calibrate";
    },

    recordTimeFormatted() {
      return formatTime(this.recordTimer);
    },
  },

  mounted() {
    window.addEventListener("blur", this.blurHandler);
    window.addEventListener("focus", this.focusHandler);
    window.addEventListener("beforeunload", this.unload_handler);
  },

  beforeDestroy() {
    window.removeEventListener("focus", this.focusHandler);
    window.removeEventListener("blur", this.blurHandler);
    window.removeEventListener("beforeunload", this.unload_handler);
    this.setDefaultProperties();
  },

  created() {
    this.popUpCount = getStorage("popUpCount");
    if (this.popUpCount == null) {
      saveLog("initializing-popupcounts");
      this.popUpCount = this.$store.state.popUpCount;
      console.log(this.popUpCount);
      setStorage({popUpCount: this.popUpCount})
    }
  },

  methods: {
    ...mapActions([STORE_SET_AUDIO_SRC, STORE_SET_RECORDING, STORE_SET_UPLOADING ]),

    getMinDurationText() {
      return (
        MSGS_MIN_DURATION[this.voiceKey] ||
        `Recording duration less than minimum ${this.minDuration} seconds. Please re-record.`
      );
    },

    checkPopUpCounts() {
      console.log("in check pop up counts")
      console.log(FORCE_RERECORD_POPUPS)
      for (var key in this.popUpCount) {
        console.log("checking key "+ key)
        if (key in this.popUpCount) {
          console.log(" key ", key, this.popUpCount[key])
          if (FORCE_RERECORD_POPUPS.includes(key) && this.popUpCount[key] > 2) {
            saveLog("trigger-session-noise-error");
            return true;
          }
        }
      }
      return false;
    },

    async startRecording() {
      saveLog("record-button-start");

      if (!isSupportedBrowser) {
        saveLog("unsupported-browser");
        return this.showModal(POPUP_NOTIFICATION, { title: MSG_UNSUPPORTED });
      }

      this[STORE_SET_UPLOADING]({
        key: this.voiceKey,
        payload: {
          blob: null,
          name: null,
          loading: false,
          error: null,
          uploaded: false,
          progress: 0
        }
      });

      try {
        this.$emit("start");

        this.loading = true;
        this.audioLossOffset = null;
        this.audioLossDuration = 0.0;
        this.recorder.session_id = this.$store.state.user.session_id;
        this.recorder.user_id = this.$store.state.user.user_id;
        this.recorder.register_lost_stream_popup(() =>
          this.lostStreamHandler()
        );

        this.recorder.voiceKey = this.voiceKey;

        var pathname = window.location.pathname.split("/");
        this.prompt_name = pathname[pathname.length - 1];

        this.recorder.StreamRegistrationPromise = this.$store.state.StreamRegistrationPromise;
        this.recorder.StreamerRegistered = this.$store.state.StreamerRegistered;
        this.recorder.save_s3_key = async (s3_key) => {
          saveLog("parent-save-s3key", {s3_key})
          this[STORE_SET_UPLOADING]({
            key: this.voiceKey,
            payload: {
              blob: null,
              name: s3_key,
              loading: false,
              error: null,
              uploaded: false,
              progress: 0
            }
          });

        }
        console.log("set recorder voice key");
        console.log(this.recorder.voiceKey);
        console.log(this.prompt_name)
        console.log(this.route)

        this.recorder.register_wavuri_handler(
          async (uri, duration, s3_key) => {
            if (!this.recording) {
              saveLog("wav-uri-handler", {msg : `setting upload status for ${this.voiceKey} - ${s3_key} to true`});
              this[STORE_SET_UPLOADING]({
                key: this.voiceKey,
                payload: {
                  blob: null,
                  name: s3_key,
                  loading: false,
                  error: null,
                  uploaded: true,
                  progress: 100
                }
              });
            }

            if (this.valid_blob == null) {
              saveLog("wav-uri-handler", {msg : "valid_blob is still null, retrying"});
              return delay(500).then(() => this.recorder.wavuri_handler(uri, duration, s3_key));
              // TODO: Add # attempts above, if exceed thresh, display lost stream popup;

            }
            if (this.valid_blob != null && !this.valid_blob) {
              saveLog("wav-uri-handler", {msg : `local bloc is invalid, setting audio source to remote ${uri}`});
              if(this.$store.state.passed[this.route]) {
                saveLog("wav-uri-handler", 
                  {msg: `${this.route} was completed, no review audio possible, skipping audio source`});
                return;
              }
              if(this.recording) {
                saveLog("wav-uri-handler", 
                  {msg: `${this.route} is being (re) recorded, skipping setting audio source`});
                return;
              }
              this[STORE_SET_AUDIO_SRC](uri);
              this.duration = duration;
              this.checkDuration(duration);
            }
            else {
              saveLog("wav-uri-handler", {msg : `local blob is valid source`});
            }
          }
        );

        this.recorder.register_set_stream_connection((status) => {
          saveLog("set-stream-connection", { status  });
          this.$store.state.StreamerConnected = status;
        });


        this.recorder.register_rtmessage_handler((msg) => {
          this.rtmessage = msg;
          const rtResult = this.convertToObject(msg);

          console.log("checking passed")
          console.log(this.route)

          if ("aggregated" in rtResult){
            this.agg_received = true;
          }

          if ((this.voiceKey == "audio7" || this.voiceKey == "audio6")
            && "aggregated" in rtResult) {  // last prompt in session
            // TODO: add check for session_metrics
            setStorage({"session_metrics": rtResult.aggregated});
          }

          var ux_enabled = true;
        
          if(this.$store.state.passed[this.route]) {
            console.log("PASSED TRUE")
            saveLog("skipped-agg-result", 
              {msg: `${this.route} was completed, next was pressed before agg results were received.`,
              metrics : rtResult});

            ux_enabled = false;
          }
          if(this.recording && "aggregated" in rtResult) {
            console.log("Received agg during re-record");
            saveLog("rerecord-agg-result",
              {msg: `${this.route} is being recorded, agg results for same prompt received, ignoring.`,
              metrics: rtResult})
            return;
          }

          console.log("COUNT TO 20 PASSED?");
          console.log(this.$store.state.passed.countTo20);
          // if(this.$store.state.passed.countTo20) {
            // saveLog("agg-after-completion", {rtResult});
            // return;
          // }
          // SUSTAINED AHH EAGER RESULT HANDLERS
          if ("aggregated" in rtResult) {
            saveLog("received-agg-result", {rtResult})
            console.log(`in handler 0, agg received : ${this.agg_received}`);
            console.log(`in handler 1, agg received : ${this.agg_received}`);
            console.log(this.voiceKey);
            /*eslint-disable */
            if (this.voiceKey == "audio2" &&
                ("noise-index" in rtResult.aggregated &&
                                rtResult.aggregated["noise-index"]["ESNR"] < 6.5 || ("segmented-ahh" in rtResult.aggregated && "error" in rtResult.aggregated["segmented-ahh"] && "error-code" in rtResult.aggregated["segmented-ahh"]["error"] && rtResult.aggregated["segmented-ahh"]["error"]["error-code"] == 4))) {
            /*eslint-enable */
              if(ux_enabled){
                this.stopRecording();
                console.log("NOISE POPUP");
                saveLog("audio-quality-fail", {
                  msg  : "noise error",
                  ESNR : rtResult.aggregated["noise-index"]["ESNR"] 
                });
                this.popUpCount.esnr++;
                setStorage({popUpCount: this.popUpCount})

                if(this.checkPopUpCounts()) {
                  this.showModal(POPUP_SESSION_NOISE_ERROR, {
                    hideCloseButton: true,
                    force: true
                  })
                }
                else {
                  this.showModal(POPUP_DETECTED_NOISE, {
                    hideCloseButton: true,
                    deleteAction: () => this.setDefaultProperties(),
                  });
                }
              }
              else {
                saveLog("skipped-quality-fail", {
                  msg  : "noise error",
                  ESNR : rtResult.aggregated["noise-index"]["ESNR"] 
                });
              }
            }
            else if (this.voiceKey == "audio2" &&
                    "user-effort" in rtResult.aggregated && rtResult.aggregated["user-effort"]["low-effort"]) 
            {
              if (ux_enabled){
                console.log("EFFORT POPUP");
                saveLog("audio-quality-fail", {
                  msg  : "effort error",
                  "effort-index" : rtResult.aggregated["user-effort"]["effort-index"],
                  phon_dur : rtResult.aggregated["segmented-ahh"]["phonation_duration"]
                });
                this.popUpCount.ahh_effort++;
                setStorage({popUpCount: this.popUpCount})
                if(this.checkPopUpCounts()) {
                  this.showModal(POPUP_SESSION_NOISE_ERROR, {
                    hideCloseButton: true,
                    force: true
                  })
                }
                else {
                  this.showModal(POPUP_DETECTED_LOW_EFFORT, {
                    hideCloseButton: true,
                    submitAction: () => {
                      saveLog("audio-quality-popup", {msg: "Submit selected"});
                    },
                    deleteAction: () =>{
                      saveLog("audio-quality-popup", {msg: "Delete selected"});
                      this.setDefaultProperties()
                    } ,
                  });
                }
              }
              else{
                saveLog("skipped-quality-fail", {
                  msg  : "effort error",
                  "effort-index" : rtResult.aggregated["user-effort"]["effort-index"],
                  phon_dur : rtResult.aggregated["duration-ahh"]["duration"]
                });
              }
            }

            // FE EAGER RESULT HANDLERS
            if (this.voiceKey == "audio3" && rtResult.aggregated && 
              ( "segmented-fe" in rtResult.aggregated || "forceful-exhale-quality-check" in rtResult.aggregated )) {
              if (ux_enabled) {
                // if (rtResult.aggregated["forceful-exhale-check"]["no-fe"]) {
                if ("segmented-fe" in rtResult.aggregated) {
                  console.log("Segmented fe in agg results")
                  if ("error" in rtResult.aggregated["segmented-fe"]) {
                    console.log("NO FE POP UP");
                    saveLog("audio-quality-fail", {
                      msg : "Fatal error from API exhale segmenter",
                      resp : rtResult.aggregated["forceful-exhale-quality-check"]
                    });
                    this.showModal(POPUP_NO_FE_DETECTED, {
                      hideCloseButton: true,
                      deleteAction : () => this.setDefaultProperties(),
                      route: ROUTE_THREE_DEEP_BREATHS
                    });
                    this.popUpCount.no_fe++;
                    setStorage({popUpCount: this.popUpCount})
                    if(this.checkPopUpCounts()) {
                      this.showModal(POPUP_SESSION_NOISE_ERROR, {
                        hideCloseButton: true,
                        force: true
                      })
                    }
                  }
                  else {
                    console.log("No error in segmented-fe");
                  }
                }
                else if ("forceful-exhale-quality-check" in rtResult.aggregated){

                  if (rtResult.aggregated["forceful-exhale-quality-check"]["no-fe"]) {
                    saveLog("audio-quality-fail", {
                      msg : "no FE detected",
                      resp : rtResult.aggregated["forceful-exhale-quality-check"]
                    });
                    this.popUpCount.no_fe++;
                    setStorage({popUpCount: this.popUpCount})
                    if(this.checkPopUpCounts()) {
                      console.log("noise-error-popup");
                      this.showModal(POPUP_SESSION_NOISE_ERROR, {
                        hideCloseButton: true,
                        force: true
                      })
                    }
                    else {
                      console.log("NO FE POP UP");
                      this.showModal(POPUP_NO_FE_DETECTED, {
                        hideCloseButton: true,
                        deleteAction : () => this.setDefaultProperties(),
                        route: ROUTE_THREE_DEEP_BREATHS
                      });
                    }
                  }

                  // else if (rtResult.aggregated["forceful-exhale-check"]["insufficient-fe"]) {
                  else if (rtResult.aggregated["forceful-exhale-quality-check"]["insufficient-fe"]) {
                    console.log("INSUFFICIENT FE POP UP");
                    saveLog("audio-quality-fail", {
                      msg : "insufficient FE detected",
                      resp : rtResult.aggregated["forceful-exhale-quality-check"]
                    });
                    this.popUpCount.insufficient_fe++;
                    setStorage({popUpCount: this.popUpCount})

                    if(this.checkPopUpCounts()) {
                      this.showModal(POPUP_SESSION_NOISE_ERROR, {
                        hideCloseButton: true,
                        force: true
                      })
                    }
                    else {
                      this.showModal(POPUP_INSUFFICIENT_FE_DETECTED, {
                        hideCloseButton: true,
                        deleteAction : () => this.setDefaultProperties(),
                        route: ROUTE_THREE_DEEP_BREATHS
                      });
                    }
                  }
                }

              }
              else{
                // if (rtResult.aggregated["forceful-exhale-check"]["no-fe"] ||
                    // rtResult.aggregated["forceful-exhale-check"]["insufficient-fe"]) {
                if (rtResult.aggregated["forceful-exhale-quality-check"]["no-fe"] ||
                    rtResult.aggregated["forceful-exhale-quality-check"]["insufficient-fe"]) {
                  saveLog("skipped-quality-fail", {
                    msg  : "forceful exhale error",
                    resp : rtResult.aggregated["forceful-exhale-quality-check"]
                  });
                }
              }
            }

            // COUGHS EAGER RESULT HANDLERS
            if (this.voiceKey == "audio4" && 
                    "cough-quality-check" in rtResult.aggregated &&
                    rtResult.aggregated["cough-quality-check"]["total_cough_frames"] < 1) 
            {
              if (ux_enabled) {
                console.log("COUGH POP UP");
                saveLog("audio-quality-fail", {
                  msg  : "cough error",
                });
                this.popUpCount.no_cough++;
                setStorage({popUpCount: this.popUpCount})
                if(this.checkPopUpCounts()) {
                  this.showModal(POPUP_SESSION_NOISE_ERROR, {
                    hideCloseButton: true,
                    force: true
                  })
                }
                else {
                  this.showModal(POPUP_NO_COUGH_DETECTED, {
                    hideCloseButton: true,
                    deleteAction: () => this.setDefaultProperties(),
                  });
                }
              }
              else {
                saveLog("skipped-quality-fail", {
                  msg  : "cough error",
                });
              }
            }


            // RP EAGER RESULT HANDLERS
            if (this.voiceKey == "audio5" && 
                    "rp-quality-check" in rtResult.aggregated &&
                    "error" in rtResult.aggregated["rp-quality-check"]) 
            {
              if (ux_enabled) {
                console.log("RP EFFORT POP UP");
                saveLog("audio-quality-fail", {
                  msg  : "rp error",
                  error : rtResult.aggregated["rp-quality-check"]
                });
                this.popUpCount.no_rp++;
                setStorage({popUpCount: this.popUpCount})
                if(this.checkPopUpCounts()) {
                  this.showModal(POPUP_SESSION_NOISE_ERROR, {
                    hideCloseButton: true,
                    force: true
                  })
                }
                else {
                  this.showModal(POPUP_NO_VOICE_DETECTED, {
                    hideCloseButton: true,
                    deleteAction: () => this.setDefaultProperties(),
                    route: ROUTE_READING_PASSAGE 
                  });
                }
              }
              else {
                saveLog("skipped-quality-fail", {
                  msg  : "rp error",
                  error : rtResult.aggregated["rp-pause-feats"]
                });
              }
            }

            // COUNT EAGER RESULT HANDLERS
            if (this.voiceKey == "audio6" && 
                    "count-quality-check" in rtResult.aggregated &&
                    "error" in rtResult.aggregated["count-quality-check"]) 
            {
              if (ux_enabled) {
                console.log("count EFFORT POP UP");
                saveLog("audio-quality-fail", {
                  msg  : "count error",
                  error : rtResult.aggregated["count-quality-check"]
                });
                this.popUpCount.no_wl++;
                setStorage({popUpCount: this.popUpCount})
                if(this.checkPopUpCounts()) {
                  this.showModal(POPUP_SESSION_NOISE_ERROR, {
                    hideCloseButton: true,
                    force: true
                  })
                }
                else {
                  this.showModal(POPUP_NO_VOICE_DETECTED, {
                    hideCloseButton: true,
                    deleteAction: () => this.setDefaultProperties(),
                    route: ROUTE_COUNT_TO_20 
                  });
                }
              }
              else {
                saveLog("skipped-quality-fail", {
                  msg  : "count error",
                  error : rtResult.aggregated["count-quality-check"]
                });
              }
            }

          
            if (this.voiceKey == "audio7" && 
                    "feeling-today-quality-check" in rtResult.aggregated &&
                    "error" in rtResult.aggregated["feeling-today-quality-check"]) 
            {
              if (ux_enabled) {
                console.log("count EFFORT POP UP");
                saveLog("audio-quality-fail", {
                  msg  : "feeling_today error",
                  error : rtResult.aggregated["feeling-today-quality-check"]
                });
                this.popUpCount.no_feeling_today++;
                setStorage({popUpCount: this.popUpCount})
                if(this.checkPopUpCounts()) {
                  this.showModal(POPUP_SESSION_NOISE_ERROR, {
                    hideCloseButton: true,
                    force: true
                  })
                }
                else {
                  this.showModal(POPUP_NO_VOICE_DETECTED, {
                    hideCloseButton: true,
                    deleteAction: () => this.setDefaultProperties(),
                    route: ROUTE_FEELING_TODAY 
                  });
                }
              }
              else {
                saveLog("skipped-quality-fail", {
                  msg  : "count error",
                  error : rtResult.aggregated["count-quality-check"]
                });
              }
            }
          }
        });

        await this.recorder.start();
        this.start_timestamp = Date.now();
        this.nosleep.enable();

        this[STORE_SET_RECORDING](true);
        this.loading = false;

        saveLog("record-start");

        if (this.isCalibration) {
          this.calibrationTimer = 5;
          return this.updateCalibrationTimer();
        }

        this.isStopBtnDisabled = true;

        return this.updateRecordTimer();
      } catch ({ message }) {
        if (message === "Permission denied") {
          this.allowed = false;
        }
        this.setDefaultProperties();
        saveLog("record-error-start", { message });
      }
    },

// function ensureFooIsSet() {
    // return new Promise(function (resolve, reject) {
        // (function waitForFoo(){
            // if (lib.foo) return resolve();
            // setTimeout(waitForFoo, 30);
        // })();
    // });
// }

    aggCheck() {
      // returns promise that resolves when this.agg_received is set to true
      return new Promise((resolve, reject) => {
        const check_received = () => {
          console.log(`in check_received, agg received : ${this.agg_received}`);
          if (this.agg_received == true) return resolve();
          else if (this.agg_received == null) return reject()
          setTimeout(check_received, 1000);
        }
        check_received();
      })
    },

    async stopRecording() {
      saveLog("record-button-stop");

      try {
        this.loading = true;
        this[STORE_SET_RECORDING](false);
        clearInterval(this.interval);

        this.nosleep.disable();
        this.$emit("stop");

        this.agg_received = false;  // not stopped the recorder yet, stream disconnect happens then


        const {
          blob,
          percentOfSilenceIOs,
          percentOfSilenceAllDevices,
          samples,
          logs,
          reinit,
          recordedDuration,
        } = await this.recorder.stop();


        saveLog("blob-test", {msg: "starting"});
        this.valid_blob = null;
        try {
          if (isAndroid) {
            await blob.arrayBuffer();
            saveLog("blob-test", {msg: "success"});
            // this.valid_blob = false;
            this.valid_blob = true;
          }
          else {
            saveLog("blob-test", { msg: "Non android device, setting blob test to success" })
            this.valid_blob = true;
          }
        }
        catch (error) {
          saveLog("blob-test", {msg: "FAILURE", error});
          this.valid_blob = false;
        }
        saveLog("blob-test", {msg: "complete"});
        if (!this.$store.state.StreamerConnected && !this.valid_blob) {
          saveLog("blob-fail-stream-fail");
          return this.lostStreamHandler();
        }

        console.log(
          `RECORD.VUE: In stopRecording, reinit_timestamp: ${reinit}`
        );

        if(this.$store.state.StreamerConnected) {
          console.log(`disabling next, agg received : ${this.agg_received}`);
          this.disabled = true;
          var threshold = 2000;
          if (!this.valid_blob) {
            threshold = 3000;
            saveLog("increase-agg-timeout", {msg: "invalid blob, wait 3s for wavuri before re-recording"})
          }
          const timeout = (prom, time) =>
            Promise.race([prom, new Promise((_r, rej) => setTimeout(rej, time))]);
          // TODO wait for agg promise, then set disabled to false
          await timeout(
            this.aggCheck().finally(() => {
              this.disabled = false;
            }),  // promise that can timeout
            threshold // timeout ms
          ).catch(() => {
            saveLog("agg-promise-timeout");
            this.disabled = false;
            this.agg_received = null; // failed to receive agg results, want to kill call to aggCheck
            if(!this.valid_blob) {
              saveLog("blob-fail-stream-fail", 
                { msg: "wavuri took longer than 3s to return and invalid blob, forcing re-record" });
              return this.lostStreamHandler();
            }
          })

        }

        this.stop_timestamp = Date.now();
        // this.recorded_duration = (this.stop_timestamp - this.start_timestamp) / 1000.0;
        this.recorded_duration = recordedDuration;

        if (reinit != false) {
          this.audioLossOffset = (reinit - this.start_timestamp) / 1000.0;
        } else {
          this.audioLossOffset = null;
        }

        saveLog("record-stop", {
          ...logs,
          silence: percentOfSilenceAllDevices,
          silence2: percentOfSilenceIOs,
          samples,
        });

        // TODO: Below if-block should no longer be required after rtfix is implemented
        if (
          (isIOS && percentOfSilenceIOs > RECORD_IOS_SILENCE) ||
          percentOfSilenceAllDevices >= RECORD_OD_SILENCE
        ) {
          saveLog("record-sil-error", {
            silence: percentOfSilenceAllDevices,
            silence2: percentOfSilenceIOs,
            samples,
          });

          return this.lostStreamHandler();
        }

        this.setAudioSource(blob);
        this.loading = false;

        if (this.voiceKey === "audio2" && this.pdr < this.threshold) {
          this.showModal(POPUP_DETECTED_LOW_EFFORT, {
            hideCloseButton: true,
            submitAction: () => this.sendRecordedVoice(),
          });
        }
      } catch ({ message }) {
        this.setDefaultProperties();
        saveLog("record-error-stop", { message });
      }
    },

    updateCalibrationTimer() {
      this.interval = setInterval(() => {
        if (this.calibrationTimer <= 0) {
          return this.stopRecording();
        }

        this.calibrationTimer -= 1;
      }, 1000);
    },

    updateRecordTimer() {
      this.recordTimer = 0;
      this.interval = setInterval(() => {
        this.recordTimer += 1;
        if(this.recordTimer >= RECORD_MAX_DURATION + 5) {
          return this.stopRecording();
        }
        this.isStopBtnDisabled = false;
      }, 1000);
    },

    lostStreamHandler() {
      this.setDefaultProperties();
      this.popUpCount.lost_stream++;
      setStorage({popUpCount: this.popUpCount})
      if(this.checkPopUpCounts()) {
        this.showModal(POPUP_SESSION_RECORDING_ERROR, {
            hideCloseButton: true,
            force: true
        });  // TODO : replace with session_recording_error wip
      }
      else {
        this.showModal(POPUP_LOST_STREAM, { force: true });
      }
    },

    checkDuration(num) {
      // if min/max duration fails:
      //    save log, force re-record and return
      // Check if audio loss > T
      // If yes, show lost stream pop up and force re-record. Save logs to show re-record was forced
      // If no:
      //   if ! re-init happened:
      //      nothing to do
      //   else:
      //      send samples data with loss offset and duration

      const aboveMin = num > this.minDuration;
      const lessMax = num <= RECORD_MAX_DURATION;
      const isWrongDuration = !this.isCalibration && (!aboveMin || !lessMax);

      if (isWrongDuration && lessMax) {
        this.setDefaultProperties();

        const title = this.getMinDurationText();

        saveLog("record-min-error");

        return this.showModal(POPUP_NOTIFICATION, { title });
      }

      if (isWrongDuration && !lessMax) {
        this.setDefaultProperties();

        const title = `Recording duration above maximum ${RECORD_MAX_DURATION} seconds. Please re-record.`;

        saveLog("record-max-error");

        return this.showModal(POPUP_NOTIFICATION, { title });
      }

      console.log(
        `Checking recording duration: num : ${num}, dur : ${this.recorded_duration}`
      );

      this.audioLossDuration = this.recorded_duration - num - 0.75; // TODO: Replace magic num with constant/env var
      if (this.audioLossDuration < 0) this.audioLossDuration = 0.0;

      saveLog("audioLoss-data", {
        audioLossDuration: this.audioLossDuration,
        audioLossOffset: this.audioLossOffset,
      });

      // if (this.audioLossDuration > 3.75) {
        // // TODO: Replace magic num threshold with constant/env var
        // saveLog("audioLoss-error", {
          // audioLossDuration: this.audioLossDuration,
          // audioLossOffset: this.audioLossOffset,
        // });
        // return this.lostStreamHandler();
      // }
    },

    setDefaultProperties() {
      clearInterval(this.interval);
      this.setAudioSource(null);
      this.calibrationTimer = 5;
      this.recordTimer = 0;
      this[STORE_SET_RECORDING](false);
      this.isStopBtnDisabled = false;
      this.loading = false;
      this.voiceBlob = null;
      this.rtmessage = "No rt message";
      this.esnr = null;
      this.prd = null;
      this.threshold = null;

      if (this.recorder.recording) {
        this.stopRecording();
      }
    },

    sendRecordedVoice() {
      if (this.audioLossOffset) {
        sendSampleData({
          session: this.user.session_id,
          audio_loss_duration: this.audioLossDuration,
          audio_loss_end_offset: this.audioLossOffset,
          audio: this.$route.meta.audioNum,
        });
      }
      saveLog("next");
      this.$emit("send-voice", this.voiceBlob);
    },

    setAudioSource(blob = null) {
      const str = blob ? window.URL.createObjectURL(blob) : null;
      this.voiceBlob = blob;
      this[STORE_SET_AUDIO_SRC](str);
    },

    unload_handler(event){
      console.log(this.audioSource);
      if (this.recording || this.audioSource) {
        event.preventDefault()
        event.returnValue = ""
      }
    },

    blurHandler() {
      saveLog("defocus");
      if (!this.recording || this.isCalibration) return;
      this.stopRecording();
    },

    focusHandler() {
      saveLog("focus");
    },

    convertToObject(str) {
      try {
        return JSON.parse(str) || {};
      } catch (error) {
        return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.record-player:not(.inline) {
  position: fixed;
  z-index: 5;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
